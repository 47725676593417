const exampleQuestions = [
    { label: "How many [treatment.area] treatments did [nurse.name] do since [date]?", isEditable: true },
    { label: "Show me the top 25 nurses based on number of treatments completed in the last 12 months?", isEditable: false },
    { label: "How many nurses are in South Australia?", isEditable: false },
    { label: "Exclude any nurses that did not do any treatments this year.", isEditable: false },
    { label: "Tell me something about [nurse.name]. Show me all the treatments by treatment area that they did with Filler.", isEditable: true }
]

export {
    exampleQuestions
}