<template>
    <div>
        <vs-button :class="{'widget-ai-btn': btnIcon}" class="ask-button" @click="popupSearchAI=true" color="primary" type="border">
            {{ title }}
            <img v-if="btnIcon" src="@/assets/images/magic_wand.svg"/>
        </vs-button>
        <vs-popup class="popup-search-ai" title="Ask FreshClinics AI" :active.sync="popupSearchAI">
            <div id="parentWrapperx" ref="parentWrapper" >
                <vs-sidebar 
                    class="sidebarWrapperx" 
                    :class="{'hidden-sidebar': reduceSidebar}" 
                    :reduce="reduceSidebar" 
                    :reduce-not-hover-expand="true" 
                    static-position hidden-background 
                    :parent="$refs.parentWrapper" 
                    color="primary"  
                    spacer 
                    v-model="activeSidebar"
                    :default-index="currentSessionId"
                    :key="sidebarWrapperKey">
                    <div class="header-sidebar" slot="header">
                        <vs-button class="newchat-btn" type="border" icon="add" @click="clearConvo">New chat</vs-button> 
                        <vs-button class="reduce-btn" icon="menu" @click="reduceSidebar=!reduceSidebar" color="primary" type="border"></vs-button>
                    </div>
                    <div v-for="(value, key) in sessionHistory" :key="key">
                        <vs-divider position="left" border-style="none">{{ key }}</vs-divider>
                        <vs-sidebar-item @click="fetchSessionHistory(session)" v-for="session in value" :key="session.session_id" :index="session.session_id" icon="chat_bubble_outline">
                            {{ getActualQuestion(session.message) }}
                        </vs-sidebar-item>
                    </div>
                </vs-sidebar>

                <vs-button class="sidebarButton" icon="menu" @click="reduceSidebar=!reduceSidebar" color="primary" type="flat"></vs-button>
                <div ref="convoWrapper" class="convoWrapperParent" id="convo-wrapper">
                    <div>
                        <vs-row vs-w="12">
                            <div class="example-message" v-if="!convoItems.length && !isFetchingSessionHistory">
                                <span class="header-title">Examples</span>
                                <ul>
                                    <li @click="submitMessage(question.label, question.isEditable)" v-for="(question, index) in defaultQuestions" :key="index"> 
                                        <vs-row vs-w="12">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                                                <vs-icon size="medium" icon="bolt"></vs-icon>
                                            </vs-col>
                                            <vs-col vs-type="flex" vs-align="center" vs-w="11">
                                                <span>
                                                    {{ question.label }}
                                                    <vs-icon class="inline-icon" v-if="question.isEditable" icon="subdirectory_arrow_right" />
                                                </span>
                                            </vs-col>
                                        </vs-row>
                                    </li>
                                </ul>
                            </div>
                        </vs-row>
                        <vs-row class="convo-item" vs-w="12" v-for="(item, index) in convoItems" :key="index">
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="center" vs-w="1">
                                    <vs-icon icon="person" round size="medium"></vs-icon>
                                </vs-col>
                                <vs-col vs-type="flex" vs-align="center" vs-w="11">
                                    <p>{{ getActualQuestion(item.question) }}</p>
                                </vs-col>
                            </vs-row>
                            <vs-row>
                                <vs-col vs-type="flex" vs-justify="center" vs-w="1">
                                    <vs-icon class="ai-icon" icon="flash_on" size="medium"></vs-icon>
                                </vs-col>
                                <vs-col vs-type="flex" vs-align="center" vs-w="11">
                                    <div v-if="!item.response" class="blinking"></div>
                                    <div class="white-space-wrap" v-if="item.response && index < convoItems.length - 1">
                                        {{ parseMessage(item.response.message, 'explanation') }}
                                        <span v-if="item.response.resultSet && item.response.resultSet.length">
                                            <BarChart
                                                v-if="item.response.isChartType"
                                                :chartData="displayResultData(item.response.resultSet, item.response.isChartType).chartData"
                                                :chartOptions="displayResultData(item.response.resultSet, item.response.isChartType).chartOptions"
                                            />
                                            <vs-table stripe :data="displayResultData(item.response.resultSet, item.response.isChartType).tableData" v-if="!item.response.isChartType">
                                                <template slot="thead">
                                                    <vs-th :key="indextr" v-for="(th, indextr) in getObjectKeys(displayResultData(item.response.resultSet, item.response.isChartType).tableData)">
                                                        {{ getKeyLabel(th) }}
                                                    </vs-th>
                                                </template>

                                                <template slot-scope="{data}">
                                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                                                        <vs-td :data="td" :key="indextd" v-for="(td, indextd) in Object.values(tr)">
                                                            {{ td }}
                                                        </vs-td>
                                                    </vs-tr>
                                                </template>
                                            </vs-table>
                                        </span>
                                    </div>
                                    <div class="white-space-wrap" v-if="item.response && index === convoItems.length - 1">
                                        <span :ref="`responseMessage${index}`"></span>
                                        <span v-if="item.response.resultSet && item.response.resultSet.length" class="file-frame" :ref="`responseChartData${index}`" style="display:none">
                                            <BarChart
                                                v-if="item.response.isChartType"
                                                :chartData="displayResultData(item.response.resultSet, item.response.isChartType).chartData"
                                                :chartOptions="displayResultData(item.response.resultSet, item.response.isChartType).chartOptions"
                                            />
                                            <vs-table stripe :data="displayResultData(item.response.resultSet, item.response.isChartType).tableData" v-if="!item.response.isChartType">
                                                <template slot="thead">
                                                    <vs-th :key="indextr" v-for="(th, indextr) in Object.keys(displayResultData(item.response.resultSet, item.response.isChartType).tableData[0])">
                                                        {{ getKeyLabel(th) }}
                                                    </vs-th>
                                                </template>

                                                <template slot-scope="{data}">
                                                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                                                        <vs-td :data="td" :key="indextd" v-for="(td, indextd) in Object.values(tr)">
                                                            {{ td }}
                                                        </vs-td>
                                                    </vs-tr>
                                                </template>
                                            </vs-table>
                                        </span>
                                        <!-- </span> -->
                                        <!-- <iframe class="file-frame" src="about:blank" :ref="`responseChartData${index}`" style="display:none"></iframe> -->
                                    </div>
                                </vs-col>
                            </vs-row>
                        </vs-row>
                    </div>
                    
                    <form @submit.prevent="submitMessage(prompt, false)">
                        <vs-row class="prompt-input-wrapper" vs-w="12">
                                <vs-input :disabled="isWaitingForAnswer" class="prompt-input" :placeholder="promptPlaceholder" v-model="prompt"/>
                                <div class="submit-message transition-opacity" :class="{'opacity-show': prompt.length }">
                                    <span>Submit message</span>
                                    <vs-icon icon="keyboard_return"></vs-icon>
                                </div>
                        </vs-row>
                    </form>
                </div>
            </div>
        </vs-popup>
    </div>
</template>
<script>
import BarChart from "../../charts/BarChart.vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { exampleQuestions } from "./aiConstant.js";
import moment from "moment";

export default {
    props: {
        title: {
            type: String,
            default: "Ask FreshClinics AI"
        },
        btnIcon: {
            type: Boolean,
            default: false
        }
    },
    components: {
        BarChart
    },
    data() {
        return {
            userId: '',
            reduceSidebar: false,
            activeSidebar: true,
            sidebarWrapperKey: 'sidebar-history', // force rendering
            popupSearchAI: false, 
            prompt: ''
        }
    },
    computed: {
        ...mapState("ai", ["convoItems", "sessionHistory", "isFetchingSessionHistory", "currentSessionId", "sessionIdDefault"]),
        defaultQuestions() {
            return exampleQuestions;
        },
        isWaitingForAnswer() {
            if(this.convoItems.length) {
                if(this.convoItems[this.convoItems.length - 1].response === '') {
                    return true;
                }
            }
            return false;
        },
        promptPlaceholder: function() {
            if(this.isWaitingForAnswer){
                return 'Waiting on an answer...'
            }
            return 'Ask FreshClinic AI a question...'
        },
    },
    watch: {
        'convoItems': {
            handler(convoItems) {
                // start the text animation
                
                this.$nextTick(() => {
                    if(convoItems.length && convoItems[convoItems.length - 1].response) {
                        this.startTextAnimation();
                    }
                });
            },
            deep: true
        },
        'sessionHistory': {
            handler(data) {
            },
        }
    },
    methods: {
        ...mapActions("ai", ["askAI", "fetchAllSessionHistoryById", "fetchSessionHistoryConvoById"]),
        ...mapMutations("ai", ["MUTATE_ADD_CONVO_ITEM", "MUTATE_CLEAR_CONVO_ITEMS", "MUTATE_CURRENT_SESSION_ID", "MUTATE_GENERATE_NEW_SESSION_ID"]),
        moment(date) {
            return moment(date);
        },
        getObjectKeys(tableData) {
            if(tableData.length) {
                return Object.keys(tableData[0])
            }
            return {}
        },
        displayResultData(data, isChartType){
            let chartDataWithTotal = {
                labels: [],
                datasets: [
                    {
                        label: "",
                        totalCount: [],
                        data: [],
                        backgroundColor: "#6124E6",
                    },
                ],
            };

            let tableData = [];
            let chartOptions = this.getChartOptions()
            
            if(Array.isArray(data) && data.length > 0) {
                const firstRowData = data[0]

                if(isChartType) {
                    // display chart
                    if(typeof firstRowData === 'object' && Object.keys(firstRowData).length === 1){
                        data.forEach(item => {
                            const keyName = Object.keys(item)[0];
                            const countValue = Object.values(item)[1];
                            chartDataWithTotal.labels.push(item[keyName]);
                            chartDataWithTotal.datasets[0].totalCount.push(countValue);
                            chartDataWithTotal.datasets[0].data.push(countValue);
                        });

                        const keyName = Object.keys(firstRowData)[0]
                        chartOptions = this.getChartOptions('', this.getKeyLabel(keyName), null)

                    } else if(typeof firstRowData === 'object' && Object.keys(firstRowData).length === 2){
                        data.forEach(item => {
                            const sortedObj = this.sortObjectFunction(item)
                            const keyName = Object.keys(sortedObj)[0];
                            const keyCount = Object.keys(sortedObj)[1];
                            chartDataWithTotal.labels.push(sortedObj[keyName]);
                            chartDataWithTotal.datasets[0].totalCount.push(sortedObj[keyCount]);
                            chartDataWithTotal.datasets[0].data.push(sortedObj[keyCount]);
                        });

                        const sortedfirstRowObj = this.sortObjectFunction(firstRowData)
                        const keyName = Object.keys(sortedfirstRowObj)[0]
                        const keyCount = Object.keys(sortedfirstRowObj)[1]
                        chartOptions = this.getChartOptions(this.getKeyLabel(keyName), this.getKeyLabel(keyCount), null)

                    } else if(typeof firstRowData === 'object' && Object.keys(firstRowData).length === 3){
                        const hasGroupedLabel = this.hasGroupedLabel(firstRowData)
                        if(hasGroupedLabel) {
                            const chartDataWithGroupedLabels = this.convertToChartDataWithGroupedLabels(data)

                            // data.forEach(item => {
                            //     const sortedObj = this.sortObjectFunction(item)
                            //     const keyBracket = Object.keys(sortedObj)[0];
                            //     const keyCount = Object.keys(sortedObj)[1];
                            //     const keyLabel = Object.keys(sortedObj)[2];
                            //     chartDataWithTotal.labels.push(keyLabel);
                            //     const dataSetWithLabel = {
                            //         label: "Below 18",
                            //         data: [
                            //             14,
                            //             4
                            //         ],
                            //         "backgroundColor": "#F9EB2C"
                            //     }
                            //     chartDataWithTotal.datasets[0].totalCount.push(sortedObj[keyCount]);
                            //     chartDataWithTotal.datasets[0].data.push(sortedObj[keyBracket]);
                            // });

                            chartDataWithTotal = chartDataWithGroupedLabels;
                            
                            const sortedfirstRowObj = this.sortObjectFunction(firstRowData)
                            const keyCount = Object.keys(sortedfirstRowObj)[1];
                            const keyLabel = Object.keys(sortedfirstRowObj)[2];
                            chartOptions = this.getChartOptions(this.getKeyLabel(keyLabel), this.getKeyLabel(keyCount), null)
                        } else {
                            data.forEach(item => {
                                const sortedObj = this.sortObjectFunction(item)
                                const keyName = Object.keys(sortedObj)[0];
                                const keyCount = Object.keys(sortedObj)[1];
                                const keyPercentage = Object.keys(sortedObj)[2];
                                chartDataWithTotal.labels.push(sortedObj[keyName]);
                                chartDataWithTotal.datasets[0].totalCount.push(sortedObj[keyCount]);
                                chartDataWithTotal.datasets[0].data.push(sortedObj[keyPercentage]);
                            });

                            const sortedfirstRowObj = this.sortObjectFunction(firstRowData)
                            const keyName = Object.keys(sortedfirstRowObj)[0]
                            const keyPercentage = Object.keys(sortedfirstRowObj)[2]
                            chartOptions = this.getChartOptions(this.getKeyLabel(keyName), this.getKeyLabel(keyPercentage))
                        }
                    }
                } else {
                    // display table
                    tableData = data;
                }
            }
            // const chartDataWithGroupedLabels = {
            //     "labels": [
            //         "Brand A",
            //         "Brand B"
            //     ],
            //     "datasets": [
            //         {
            //             "label": "",
            //             "totalCount": [
            //                 330,
            //                 6330
            //             ],
            //             "data": [
            //                 0.291125951673092,
            //                 5.584325073002038
            //             ],
            //             "backgroundColor": "#6124E6"
            //         }
            //     ]
            // }

            // const chartDataWithGroupedLabels = {
            //     "chartData": {
            //         "labels": [
            //             "Month Year A",
            //             "Month Year B"
            //         ],
            //         "datasets": [
            //             {
            //                 "label": "Below 18",
            //                 "data": [
            //                     14,
            //                     4
            //                 ],
            //                 "backgroundColor": "#F9EB2C"
            //             },
            //             {
            //                 "label": "18 - 29",
            //                 "data": [
            //                     16276,
            //                     26424
            //                 ],
            //                 "backgroundColor": "#14A7A9"
            //             },
            //             {
            //                 "label": "30 - 39",
            //                 "data": [
            //                     27429,
            //                     44128
            //                 ],
            //                 "backgroundColor": "#E17C18"
            //             }
            //         ]
            //     }
            // }

            return {
                chartData: chartDataWithTotal,
                chartOptions: chartOptions,
                tableData: tableData
            }
        },
        convertToChartDataWithGroupedLabels(resultSet) {
            const labelsSet = new Set();
            const dataBrackets = {};

            resultSet.forEach(item => {
                const sortedObj = this.sortObjectFunction(item)
                const keyLabel = Object.keys(sortedObj)[2];
                const labelValue = sortedObj[keyLabel];
                labelsSet.add(labelValue);
            });
            
            const labels = Array.from(labelsSet);

            resultSet.forEach(item => {
                const sortedObj = this.sortObjectFunction(item)
                
                const keyBracket = Object.keys(sortedObj)[0];
                const keyCount = Object.keys(sortedObj)[1];
                const keyLabel = Object.keys(sortedObj)[2];
                const labelValue = sortedObj[keyLabel];
                const bracket = sortedObj[keyBracket];
                const total = sortedObj[keyCount];

                if (!dataBrackets[bracket]) {
                    dataBrackets[bracket] = new Array(labelsSet.size).fill(0);
                }
                dataBrackets[bracket][labels.indexOf(labelValue)] = total;
            });

            const datasets = [];
            let idx = 0
            for (const bracket in dataBrackets) {
                datasets.push({
                    label: bracket,
                    data: dataBrackets[bracket],
                    backgroundColor: this.getSpecificColor(idx)
                });
                idx++;
            }

            return {
                labels,
                datasets
            };
        },
        getSpecificColor(index) {
            const colors = [
                "#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6",
                "#E6B333", "#3366E6", "#999966", "#99FF99", "#B34D4D",
                "#80B300", "#809900", "#E6B3B3", "#6680B3", "#66991A",
                "#FF99E6", "#CCFF1A", "#FF1A66", "#E6331A", "#33FFCC"
            ];

            return colors[index % colors.length];
        },
        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        getKeyLabel(key) {
            const underscoreIndex = key.indexOf('_');
            if (underscoreIndex !== -1) {
                const words = key.split('_').map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`);
                return words.join(' ');
            }
            return key.charAt(0).toUpperCase() + key.slice(1);
        },
        getChartOptions(xTitle = 'Brand', yTitle, yMax = 100){
            return {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                        position: 'top',
                        labels: {
                            boxWidth: 12
                        }
                    },
                    title: {
                        display: true,
                        text: ''
                    },
                    tooltip: {
                        callbacks: {
                            footer: function(context) {
                                const contextItem = context[0]
                                const totalCount = contextItem.dataset.totalCount 
                                let totalCountLabel = "";

                                if(totalCount){
                                    if(totalCount.length){
                                        totalCountLabel = 'Count: ' + totalCount[contextItem.dataIndex];
                                    } else {
                                        totalCountLabel = 'Count: ' + totalCount;
                                    }
                                }
                                return totalCountLabel;
                            },
                        }
                    }
                },
                scales: {
                    y: {
                        title: {
                            display: (yTitle) ? true : false,
                            // text: this.byOverTime ? '% Brand Share' : 'Share of Treatments (%)'
                            text: yTitle
                        },
                        min: 0,
                        // max: this.byNurse || this.byClinic ? 100 : null,
                        max: yMax
                        // stacked: this.isStacked,
                    },
                    x: {
                        title: {
                            display: (xTitle) ? true : false,
                            // text: this.byNurse ? "Nurse" : this.byClinic ? "Clinic" : this.byOverTime ? "Month - Year" : "Brand"
                            text: xTitle
                        },
                        grid : {
                            display : false
                        },
                        // stacked: this.isStacked,
                        ticks: {
                            font: {
                                size: 10
                            },
                            // maxRotation: this.byNurse || this.byClinic ? 90 : 0,
                            // minRotation: this.byNurse || this.byClinic ? 90 : 0,
                        }
                    }
                }
            }
        },
        hasGroupedLabel(data) {
            const keywords = ["month", "week", "year", "day"];

            for (const key in data) {
                for (const keyword of keywords) {
                    if (key.includes(keyword)) {
                        return true;
                    }
                }
            }

            return false;
        },
        sortObjectFunction(data) {
            const stringKeys = [];
            const numberKeys = [];
            const totalKeys = [];

            for (const key in data) {
                if (typeof data[key] === 'string') {
                    stringKeys.push(key);
                } else if (typeof data[key] === 'number') {
                    if (key.includes('total')) {
                        totalKeys.push(key);
                    } else {
                        numberKeys.push(key);
                    }
                }
            }

            const sortedKeys = stringKeys.concat(totalKeys).concat(numberKeys);

            const sortedData = {};
            sortedKeys.forEach(key => {
                sortedData[key] = data[key];
            });

            return sortedData;
        },
        clearConvo() {
            this.MUTATE_CLEAR_CONVO_ITEMS()
            this.MUTATE_GENERATE_NEW_SESSION_ID()
            this.refreshSidebarWrapperKey()
        },
        refreshSidebarWrapperKey() {
            this.sidebarWrapperKey = 'sidebar-history-' + Date.now() + Math.random();
        },
        fetchSessionHistory(session) {
            this.MUTATE_CURRENT_SESSION_ID(session.session_id);
            const payload = {
                "userId": this.userId
            }
            this.fetchSessionHistoryConvoById(payload)
        },
        sessionHistorySidebarRefresh() {
            const payload = { 'userId': this.userId };            
            this.fetchAllSessionHistoryById(payload)
        },
        addQuestion(message) {
            const originSessionId = this.currentSessionId
            const currentConvo = { 'userId': this.userId, 'question': message, 'response': '' };
            this.MUTATE_ADD_CONVO_ITEM(currentConvo)
            this.askAI(currentConvo).then((res) => {
                if(originSessionId === this.sessionIdDefault) {
                    this.sessionHistorySidebarRefresh()
                    this.refreshSidebarWrapperKey()
                }
            })
        },
        submitMessage(prompt, isEditable) {
            if(!isEditable) {
                this.addQuestion(prompt)
                this.prompt = '';
                this.updateConvoWrapperScroll();
            } else {
                this.prompt = prompt
            }
        },
        // type one text in the typwriter
        // keeps calling itself until the text is finished
        typeWriter(text, i, fnCallback) {
            // chekc if text isn't finished yet
            if (i < (text.length)) {
                const responseMessageRef = `responseMessage${this.convoItems.length - 1}`
                // add next character to responseMessage container
                this.$refs[responseMessageRef][0].innerHTML = text.substring(0, i+1);
                const self = this;
                // wait for a while and call this function again for next character
                setTimeout(function() {
                    self.typeWriter(text, i + 1, fnCallback)
                    self.updateConvoWrapperScroll()
                }, 10);
            }
            // text finished, call callback if there is a callback function
            else if (typeof fnCallback == 'function') {
                // call callback after timeout
                setTimeout(fnCallback, 100);
            }
        },
        // start a typewriter animation for a text in the dataText array
        startTextAnimation() {
            const dataText = this.parseMessage(this.convoItems[this.convoItems.length - 1].response.message, 'explanation')
            // const dataHtml = this.convoItems[this.convoItems.length - 1].response.html
            const self = this;

            if(!this.isFetchingSessionHistory) {
                // text exists! start typewriter animation
                this.typeWriter(dataText, 0, function(){
                    // after callback (and whole text has been animated), start next text
                    // self.loadIFrame(dataHtml)
                    self.loadIFrame(true)
                    setTimeout(function() {
                        self.updateConvoWrapperScroll()
                    }, 1000);
                });
            } else {
                const responseMessageRef = `responseMessage${this.convoItems.length - 1}`
                this.$refs[responseMessageRef][0].innerHTML = dataText;
                self.updateConvoWrapperScroll()
            }
        },
        loadIFrame(dataHtml) {
            if(dataHtml){
                
                const responseChartDataRef = `responseChartData${this.convoItems.length - 1}`
                // display bar chart
                this.$refs[responseChartDataRef][0].style.display = "block";

                // const doc = this.$refs[responseChartDataRef][0].contentWindow.document;
                
                // console.log('this.$refs[responseChartDataRef]',  this.$refs[responseChartDataRef])
                // console.log('contentWindow doc',  this.$refs[responseChartDataRef][0].contentWindow)
                // console.log('FileFrame doc',  doc)

                // doc.open();
                // doc.write(atob(dataHtml));
                // doc.close();
            }
        },
        updateConvoWrapperScroll(){
            this.$nextTick(() => {
                const element = this.$refs.convoWrapper;
                // console.log('convoWrapper',element)
                element.scrollTop = element.scrollHeight;
                // console.log('element', element.scrollTop, element.scrollHeight)
            });
        },
        parseMessage(msg, key) {
            try {
                const parsedJSON = JSON.parse(msg);
                if (Array.isArray(parsedJSON) && parsedJSON.length) {
                    return parsedJSON[0][key]
                } else if (typeof parsedJSON === 'object') {
                    return parsedJSON[key]
                }
                return msg
            } catch (error) {
                return msg
            }
        },
        getActualQuestion(msg) {
            const pattern = /This is my question:(.*?)\n/;
            const match = pattern.exec(msg);

            if (match && match[1]) {
                const extractedString = match[1];
                return extractedString
            } 
            return this.parseMessage(msg, 'question')
        }
        // openLoadingDisplay(){
        //     this.$nextTick(() => {
        //         this.$vs.loading({
        //             container: '.loading-display',
        //             type: 'radius',
        //             scale: 0.6
        //         })
        //     });
        // },
        // closeLoadingDisplay() {
        //     this.$vs.loading.close('.loading-display')
        // }
    },
    created() {
        let userInfo = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`))
        this.userId = userInfo.id

        this.sessionHistorySidebarRefresh()
    }
}
</script>
<style>
#parentWrapperx {
    overflow: hidden;
    position: relative;
    display: flex;
}
#parentWrapperx .sidebarButton {
    position: absolute;
}
#parentWrapperx .vs-sidebar--header {
    border-bottom: none;
    padding: 0;
}
#parentWrapperx .header-sidebar {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}
#parentWrapperx .header-sidebar .newchat-btn {
    width: 100%;
    margin-right: 10px;
}
#parentWrapperx .header-sidebar .reduce-btn {
    margin-right: 10px;
}
#parentWrapperx .header-sidebar h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
#parentWrapperx .header-sidebar h4 > button {
    margin-left: 10px;
}
#parentWrapperx .sidebarWrapperx {
    -webkit-transition: all 0.5s linear;
    -moz-transition: all 0.5s linear;
    -o-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
#parentWrapperx .sidebarWrapperx.hidden-sidebar .vs-sidebar-parent {
    width: 0;
    overflow: hidden;
}
#parentWrapperx .sidebarWrapperx .vs-sidebar-parent {
    width: 340px;
}
#parentWrapperx .sidebarWrapperx .vs-sidebar {
    white-space: nowrap;
}
#parentWrapperx .sidebarWrapperx .vs-divider {
    color: #999;
    margin-bottom: 5px;
}
#parentWrapperx .sidebarWrapperx .vs-sidebar--items {
    overflow-y: auto;
    margin-top: 20px;
    padding-top: 0;
    max-height: 700px;
}
#parentWrapperx .sidebarWrapperx .vs-sidebar--item a{
    color: #000;
    max-width: 250px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
#parentWrapperx .sidebarWrapperx .vs-sidebar--item a:hover{
    background-color: #074230;
    border-radius: 0.5em;
    color: #fff;
}
#parentWrapperx .sidebarWrapperx .vs-sidebar--item a i{
    position: relative;
    top: 3px;
}
.ask-button {
    height: 35px;
    padding: 5px 15px !important;
}
.popup-search-ai .vs-popup {
    width:80%;
    max-width: 1000px;
}
.popup-search-ai .vs-popup--title h3 {
    color: rgba(var(--vs-primary), 1) !important;
}
.prompt-input {
    width: 100%;
    padding: 10px 10px 0;
}
.prompt-input .vs-inputx {
    padding-right: 12rem !important;
}
.prompt-input .input-span-placeholder {
    color: #888;
}
.prompt-input-wrapper {
    max-width: 48rem;
    position: absolute;
    bottom: 0;
}
.submit-message {
    position: absolute;
    top: 15px;
    right: 17px;
    opacity: 0;
}
.submit-message span {
    position: relative;
    top: -2px;
}
.submit-message .vs-icon {
    border-radius: 0.25rem;
    background-color: #eee;
    color: #bbb;
    width: 2rem;
    height: 2rem;
    line-height: 28px;
    margin-left: 5px;
}
.opacity-show {
    opacity: 1;
}
.transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .7s;
}
.example-message .header-title {
    color: #999;
}
.example-message li {
    padding: 10px 5px;
}
.example-message li .vs-icon {
    margin-right: 5px;
    color: rgba(var(--vs-primary), 1);
}
.example-message li:hover {
    background-color: #eee;
    border-radius: 10px;
    cursor: pointer;
}
.example-message .inline-icon {
    padding-left: 3px;
    color: #000;
    position: relative;
    top: 2px;
}
.convo-item .vs-row {
    margin: 10px 0;
}
.convo-item .ai-icon {
    color: rgba(var(--vs-primary), 1);
}
.convo-item .white-space-wrap {
    white-space: pre-wrap;
}

.blinking {
    overflow: hidden;
    white-space: nowrap;
    border-right: 8px solid;
    width: 0;
    height: 15px;
    animation: blinking 1s infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blinking {
  0% {border-color: #ddd}
  50% {border-color: #888}
  100% {border-color: #ddd}
}

.convoWrapperParent {
    padding-right: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 720px;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto 50px;
}
.file-frame {
    margin-top: 8px;
    width: 100%;
    border: none;
}
.widget-ai-btn {
    background: rgba(var(--vs-primary),1)!important;
    padding: 0px 10px !important
}
.widget-ai-btn:hover {
    background: rgba(var(--vs-primary),1)!important;
    -webkit-box-shadow: 0 8px 25px -8px rgba(var(--vs-primary),1);
    box-shadow: 0 8px 25px -8px rgba(var(--vs-primary),1);
}
.widget-ai-btn img {
    width: 18px;
}
</style>